








import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import VButton from '@/components/VButton.vue';
import { Cao } from '@/interfaces/cao';
import { yesNo } from '@/utilities/filters';

@Component({
  components: { VButton },
  filters: { yesNo },
})
export default class CaoModal extends Vue {
  @Prop({ required: true }) private modalId!: string;
  @Prop({ required: true }) private cao!: Cao;
  @Prop() private show = false;

  @Watch('show')
  private onShowChanged(value: boolean, oldValue: boolean) {
    if (value) {
      (this.$refs[this.modalId] as any).show();
    } else {
      (this.$refs[this.modalId] as any).hide();
    }
  }
}
