


















































import { Component, Vue } from 'vue-property-decorator';
import EntriesCard from '@/components/cards/EntriesCard.vue';
import VButton from '@/components/VButton.vue';
import CaoModal from '@/components/modals/CaoModal.vue';
import { Action, Getter } from 'vuex-class';
import * as types from '@/store/main/types';
import { Cao } from '@/interfaces/cao';

@Component({
  components: { CaoModal, EntriesCard, VButton },
  name: 'Caos',
})
export default class Caos extends Vue {

  @Action('getCaos', { namespace: 'main' }) private getCaos!: types.GetCaosAction;
  @Action('getCaoNames', { namespace: 'main' }) private getCaoNames!: types.GetCaoNamesAction;
  @Getter('isLoggedIn', { namespace: 'account' }) private isLoggedIn!: boolean;
  @Getter('trialEnded', { namespace: 'account' }) private trialEnded!: boolean;

  private caoList: Array<{ value: Cao, text: string }> = [];
  private selectedCao: Cao = new Cao();
  private loadingCaos = false;
  private showCaoText = false;
  private caosFound = 0;
  private searchFunction = (entry: { text: string, value: Cao }, search: string) => {
    return entry.text.toLowerCase().includes(search);
  }

  private created() {
    this.loadCaos();
  }
  private loadCaos(): void {
    this.loadingCaos = true;

    // TODO: handle this in getCao action, parse everything to Cao type there
    if (!this.isLoggedIn || this.trialEnded) {
      this.getCaoNames().then((response) => {
        this.caoList = response.map((elem) => {
          return { value: new Cao(), text: elem.readable_name_year };
        });
      }).finally(() => {
        this.loadingCaos = false;
      });
    } else {
      this.getCaos().then((response) => {
        this.caoList = response;
      }).finally(() => {
        this.loadingCaos = false;
        this.caosFound = this.caoList.length;
      });
    }
  }

  private openSource(cao: Cao) {
    if (this.isLoggedIn) {
      window.open(cao.cao_url);
    } else {
      this.$router.push({ name: 'register' });
    }
  }

  private openCao(cao: Cao) {
    if (this.isLoggedIn && !this.trialEnded) {
      this.selectedCao = cao;
      this.showCaoText = true;
    } else if (this.trialEnded) {
      (this.$refs['trial-ended-modal-1'] as any).show();
    } else {
      this.$router.push({ name: 'register' });
    }
  }

  private filter(searchText: string) {
    const trimmedSearchText = searchText.trim().toLowerCase();
    const filteredList = this.caoList.filter((cao) => {
      return cao.text.toLowerCase().includes(trimmedSearchText);
    });
    this.caosFound = filteredList.length;
    return filteredList;
  }
}
